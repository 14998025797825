<template>
  <div key="province_set">
    <div class="head-container">
      <el-button class="filter-item" type="primary" :loading="submiting" :disabled="!changedItems || !changedItems.length" @click="submit">保存</el-button>
      <!-- <el-button class="filter-item" type="danger" icon="el-icon-delete" :disabled="!selection || !selection.length" :loading="delLoading" @click="batchRemove">批量删除</el-button> -->
    </div>
    <el-table :data="store" height="200" v-loading="loading" v-if="sku">
      <el-table-column prop="provinceName" label="省份" min-width="100" />
      <el-table-column label="零售价" width="180">
        <template slot-scope="scope">
          <price-input v-model="scope.row.price" :min="0" style="width: 100%;" />
        </template>
      </el-table-column>
    </el-table>
    <span class="fc-g" v-else>请先在左侧选择要进行零售价管理的商品。</span>
  </div>
</template>

<script>
import request from "@/utils/request";
import { batchEditByArea } from "@/api/retailPrice";

export default {
  props: {
    sku: Object
  },
  watch: {
    sku: "loadPrices"
  },
  data() {
    return {
      loading: false,
      submiting: false,
      provinces: [],
      store: []
    };
  },
  computed: {
    changedItems() {
      return this.store.filter(o => {
        return o.price !== o._originPrice;
      });
    }
  },
  methods: {
    loadProvince() {
      request({
        url: "api/regionalDict/province",
        method: "get"
      }).then(res => {
        this.provinces = res;
        this.loadPrices();
      });
    },
    loadPrices() {
      if (this.sku && this.sku.id) {
        this.loading = true;
        request({
          url: "api/shop/areaPrice",
          method: "get",
          params: {
            page: 0,
            size: 100,
            goodsSkuId: this.sku.id,
            provinceCodeNot: "all",
            cityCode: "all"
          }
        })
          .then(res => {
            let ds = res.content;
            this.store = this.provinces.map(p => {
              let pi = ds.find(d => {
                  return d.provinceCode === p.code;
                }),
                v = this.sku.retailPrice || 0;
              if (pi) {
                v = pi.price;
              }
              return {
                id: pi ? pi.id : null,
                goodsSkuId: this.sku.id,
                provinceCode: p.code,
                provinceName: p.name,
                cityCode: null,
                cityName: null,
                districtCode: null,
                districtName: null,
                price: v,
                _originPrice: v
              };
            });
          })
          .finally(_ => {
            this.loading = false;
          });
      }
    },
    submit() {
      if (this.changedItems && this.changedItems.length) {
        this.submiting = true;
        batchEditByArea(this.changedItems)
          .then(res => {
            this.$notify({
              title: "设置零售价成功",
              type: "success",
              duration: 2500
            });
            this.loadPrices();
          })
          .finally(_ => {
            this.submiting = false;
          });
      }
    }
  },
  mounted() {
    this.loadProvince();
  }
};
</script>